$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;
 // @media (max-width:	400px)
 // @media (max-width:	799px)
  // @media (max-width:	1279px)
.container{
  padding: 0 40px!important;
	@media (max-width:	400px){
		padding: 0 20px!important;
	}
}

$grid-breakpoints: (
  xs: 0px,// Extra small screen / phone
  sm: 401px,// Small screen / phone
  md: 800px,// Medium screen / tablet
  lg: 1280px,// Large screen / desktop
  xl: 1400px // Extra large screen / wide desktop

) !default;

$container-max-widths: (
	sm: 800px,
  md: 1160px,
  lg: 1161px,
  xl: 1163px
) !default;
