.alert{
	background: url('../images/info.svg') no-repeat 16px 8px;
	background-size: 32px 32px;
	padding: 11.5px 0;
	padding-left: 53px;
	padding-right: 16px;
	border-radius: rem(8px);
	margin-top: 22px;
	margin-bottom: 22px;
	@media (max-width: 400px){
		background: url('../images/info.svg') no-repeat 24px 34px;
		padding: 34px 0;
		padding-left: 61px;
		padding-right: 61px;
		p {
			font-size: 16px;
		}
	};
	&-error{
		background-color: $error;
	};
	&-info{
		background-color: $info;
	};
	&-warning{
		background-color: $warning;
	};
	& > p{
		display: inline-block;
		color: $violet-100;
		font-size: 18px;
		line-height: 25px;
	}
}

