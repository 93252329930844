.header {
	padding: 0 12px 0 45px;
	position: relative;
	background-color: $violet-100;

	@media (max-width: 880px) {
		padding: 22px 12px 18px 45px;
	}
	@media (max-width: 400px) {
		padding: 22px 0 18px;
	}
	&__wrapper {
		height: 136px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: static;
		@media (max-width: 880px) {
			height: auto;
			flex-wrap: wrap;
		}
	}
	&__logo {
		display: block;
		img {
			width: 374px;
			height: 50px;

			@media (max-width: 1279px) {
				width: 280px;
			}

			@media (max-width: 1024px) {
				height: 40px;
				width: 50px;
				object-fit: cover;
				object-position: left;
			}
		}
	}
	&__menu {
		color: $white;
		display: none;
		font-weight: 600;
		padding: 0;
		@media (max-width: 880px) {
			display: block;
		}
		&:after {
			content: "";
			margin-left: 9px;
			vertical-align: middle;
			display: inline-block;
			width: 32px;
			height: 32px;
			background: url(../images/menu-white.svg);
			background-size: contain;
		}

		&:hover {
			color: $white;
		}
	}
	&__nav {
		@media (max-width: 880px) {
			text-align: left;
			display: none;
			padding: 30px 49px 2px;
			width: 100%;

			background-color: $violet-100;

			// to float v
			// position: absolute;
			// top: 100%;
			// z-index: 100;
			// left: 0;
		}
		&.active,
		&:focus-within {
			@media (max-width: 800px) {
				display: block;
			}
		}
		.btn {
			font-size: rem(16px);
			color: $beige-10;
			text-decoration: none;
			padding: 0;
			margin-left: 24px;
			&:hover {
				color: $beige-10;
			}
			&.active {
				font-weight: 600;
			}
			@media (max-width: 880px) {
				text-align: left;
				font-weight: 600;
				margin: 0 0 24px;
				font-size: rem(20px);
				display: block;
			}
		}

		.searchMobile {
			margin-bottom: 24px;
			display: none;
			@media (max-width: 880px) {
				display: block;
			}
			&__field {
				&--wrapper {
					border-radius: 0px 35px 35px 0px;
					overflow: hidden;
					border: 1px solid $violet-100;
					position: relative;
					::-webkit-input-placeholder {
						/* Edge */
						color: $violet-100;
					}

					:-ms-input-placeholder {
						color: $violet-100;
					}

					::placeholder {
						color: $violet-100;
					}

					input {
						padding: 10px 20px 10px 20px;
						font-weight: 500;
						font-size: rem(18px);
						line-height: rem(18px);
						color: $violet-100;
						border: none;
						width: 100%;
						padding-right: 70px;
						&:active,
						&:focus {
							outline: none;
						}
					}

					button {
						border: none;
						height: 100%;
						width: 48px;
						background-color: $beige-10;
						font-weight: 500;
						font-size: rem(18px);
						line-height: rem(18px);
						position: absolute;
						top: 0;
						right: 0;
						&:after {
							content: "";
							width: 18px;
							height: 18px;
							position: absolute;
							margin-top: -9px;
							top: 50%;
							right: 15px;
							background: url(../images/search-violet.svg) no-repeat center center;
							background-size: 100%;
						}
					}
				}
			}
		}

		.search {
			display: inline-block;
			position: relative;
			@media (max-width: 880px) {
				display: none;
			}
			a {
				position: relative;
				padding-right: 23px;
				&:after {
					content: "";
					width: 18px;
					height: 18px;
					position: absolute;
					margin-top: -9px;
					top: 50%;
					right: 0;
					background: url(../images/search-beige.svg) no-repeat center center;
					background-size: 100%;
				}
			}
		}
		&__language {
			display: none;
			@media (max-width: 880px) {
				font-size: rem(18px);

				color: $beige-10;
				font-weight: 600;
				display: block;
				margin-top: 40px;
				span {
					margin-bottom: 8px;
					display: block;
				}
				.btn {
					text-decoration: underline;
					font-weight: 400;
					font-size: rem(18px);
				}
			}
		}
	}
	&--open-mobile {
		@media (max-width: 880px) {
			background-color: $violet-100;
			.header {
				&__wrapper {
					height: auto;
				}
				&__menu {
					&:after {
						background-image: url(../images/menu-x-white.svg);
					}
				}
				&__nav {
					display: block;
				}
			}
		}
	}

	.search__field {
		position: absolute;
		background: $beige-10;
		top: 98px;
		right: -424px;
		padding: 13px 32px;
		box-shadow: 8px 4px 28px rgba(0, 0, 0, 0.15);
		transition: all 300ms;
		&.active {
			right: 0px;
		}
		&--wrapper {
			width: 360px;
			border-radius: 0px 35px 35px 0px;
			overflow: hidden;
			border: 1px solid $violet-100;
			position: relative;
			::-webkit-input-placeholder {
				/* Edge */
				color: $violet-100;
			}

			:-ms-input-placeholder {
				color: $violet-100;
			}

			::placeholder {
				color: $violet-100;
			}

			input {
				padding: 10px 100px 10px 20px;
				font-weight: 500;
				font-size: rem(18px);
				line-height: rem(18px);
				color: $violet-100;
				border: none;
				&:active,
				&:focus {
					outline: none;
				}
			}

			button {
				border: none;
				height: 100%;
				padding: 10px 44px 12px 12px;
				background-color: $violet-100;
				color: #ffffff;
				font-weight: 500;
				font-size: rem(18px);
				line-height: rem(18px);
				position: absolute;
				top: 0;
				right: 0;
				&:after {
					content: "";
					width: 18px;
					height: 18px;
					position: absolute;
					margin-top: -9px;
					top: 50%;
					right: 15px;
					background: url(../images/search-light.svg) no-repeat center center;
					background-size: 100%;
				}
			}
		}
	}
}
