// CORE ELEMENTS
body {

  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
	}

  .page{
		overflow: hidden;
		min-height: 100vh;
		display: flex;
		flex-direction: column;

		&__content{
			flex-grow: 1;

			&--article{
				background-color: $beige-10;
			}
		}

  }

	a {
		color: inherit;
		font-weight: 600;
		text-decoration: underline;
		border-radius: 5px;
		&:focus-visible {
			box-shadow: 0 0 0 3px rgba($beige-40, .6);
			outline: none;
		}
		&:hover {
			color: inherit;
		}
		&[href^="tel:"] {
			text-decoration: none;
			font-weight: inherit;
		}
	}

	.section{
		margin-bottom: 48px;
		@media(max-width: 400px){
			margin-bottom: 24px;
		}
		&:last-of-type{
			padding-bottom: 128px;
			margin-bottom: 0;
			@media(max-width: 799px){
				padding-bottom: 40px;
			}
			> div:first-of-type{
				padding-bottom: 0;
			}
		}
		&--bgBlue,
		&--bgBeige {
			padding-top: 64px;

			@media (max-width: 799px) {
				padding-top: 40px;
			}
			@media (max-width: 400px) {
				padding-top: 24px;
			}
		}

		&--bgBlue {
			background-color: $blueLight;
		}

		&--bgBeige {
			background-color: $beige-10;
		}

		&[data-portal-region="firstRegion"]{
			margin-bottom: 0;
			padding-bottom: 48px;

			@media (max-width: 400px) {
				padding-bottom: 24px;
			}
		}
	}
}

.ml-0{
	margin-left: 0px !important;
}
.mb-16{
	margin-bottom: 16px !important;
}
.mb-32{
	margin-bottom: 32px !important;
}
.mt-32{
	margin-top: 32px !important;
}
