$blue: #00aeef;
$blueLight: #c9ecfc;
$blueDark: #23205d;
$gray: #bbbcc0;
$grayLight: #f5f5f5;
$red: #cc0000;
$pink: #cc0000;
$yellow: #fcf4c9;
$black: #000;
$black2: #22282b;


// april 2022 redesign
$violet-100: #291229;
$violet-80: #5D295D;
$violet-60: #924092;
$violet-40: #BA62BA;
$violet-20: #D197D1;
$violet-10: #E8CBE8;

$beige-100: #2A211C;
$beige-80: #544138;
$beige-60: #7E6254;
$beige-40: #A38475;
$beige-20: #BFAA9F;
$beige-10: #DBCFC9;

$yellow-100: #A56900;
$yellow-80: #544138;
$yellow-60: #F89D00;
$yellow-40: #FFBD4B;
$yellow-20: #FFDB9E;
$yellow-10: #FFEDCF;

$red-100: #5C1200;
$red-80: #B82500;
$red-60: #FF4212;
$red-40: #FF724F;
$red-20: #FFAB9C;
$red-10: #FFD0C4;

$white: #ffffff;
//--------------------

$error: #ffbab4;
$info: $violet-10;
$warning: $yellow-10;

.lighter-black {
	color: $black2;
}
.dark-blue {
	color: $blueDark;
}
