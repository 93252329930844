@mixin background-image($image, $width, $height) {
  background-image: url($path+$image);
  background-repeat: no-repeat;
  background-position: center center;
  width: $width + px;
  height: $height + px;
  content: "";
  display: inline-block;
  background-size: cover;
}

$html-font-size: 16px;
@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}
