@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
	font-size: 16px;
	hyphens: auto;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-family: "Poppins", sans-serif;
}

h1, h2, h3, h4{
	font-weight: 500;
}
h3, h4 {
	font-weight: 600;
}

h1.hero {
	font-size: rem(56px);
	line-height: rem(75px);
	margin-top: 60px;
	margin-bottom: 36px;
	color: $blueDark;
	@media (max-width:	1279px){
		font-size: rem(40px);
		line-height: rem(48.48px);
	}
	@media (max-width: 400px) {
		font-size: rem(36px);
		line-height: rem(42.26px);
		margin-top: 23px;
		margin-bottom: 32px;
	}
}
h1 {
	font-size: rem(48px);
	line-height: rem(61px);

	@media (max-width:	1279px){
		font-size: rem(32px);
		line-height: rem(43.58px);
	}
}
h2 {
	font-size: rem(32px);
	line-height: 1.25;
	@media (max-width:	1279px){
		font-size: rem(24px);
	}
}
h3 {
	font-size: rem(24px);
	line-height: 1.25;
	@media (max-width:	1279px){
		font-size: rem(20px);
		line-height: 1.3;
	}
}
h4 {
	font-size: rem(20px);
	line-height: 1.2;
}

ul {
	margin-top: 16px !important;
	li {
		hyphens: none;
		-ms-hyphens: none;
		-webkit-hyphens: none;
		-moz-hyphens: none;
		margin-bottom: 16px;
		font-size: 18px;
		line-height: 25px;
		&:last-of-type {
			margin-bottom: 0px;
		}
	}
}

.ingress {
	font-size: rem(21px) !important;
	line-height: 1.5;
	@media(max-width: 400px){
		font-size: 19px;
	}
}
