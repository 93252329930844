.accordion {
    width: 100%;
    margin-bottom: 64px;
    overflow: hidden;

    &:last-child {
        margin-bottom: 0;
    }

		& + .accordion{
			margin-top: -48px;
		}

    & + p, + h3, + h4 {
				margin-top: 16px;
    }
    &__btn {
        color: $violet-100;
        text-align: left;
        padding: 11px 48px 11px 24px;
        border: none;
        border-radius: 0;
        width: 100%;
        min-height: 56px;
        position: relative;
        &:before {
            content: '';
            border-radius: 1px;
            margin-top: -1px;
            width: 20px;
            height: 2px;
            background-color: $violet-100;
            position: absolute;
            right: 24px;
            top: 50%;
        }
        &:after {
            content: '';
            border-radius: 1px;
            margin-right: 9px;
            margin-top: -10px;
            width: 2px;
            height: 20px;
            background-color: $violet-100;
            position: absolute;
            right: 24px;
            top: 50%;
        }
    }
    &__content{
        padding: 24px;
        width: 100%;
				display: none;
				a{
					word-break: break-all;
				}
    }
    &--blue {
        background-color: $blueLight;
        margin-bottom: 8px;
    }
    &--outline {
        background-color: $beige-20;
				margin-top: 32px;

        .accordion {
            &__btn {
                font-weight: 600;
                border: 1px solid $violet-100;
                min-height: 48px;

								&:hover{
									color: $violet-100;
								}
            }
            &__content {
                padding: 16px 24px;
								border: 1px solid $violet-100;
								border-top: none;
								color: $violet-100;
								a{
									word-break: break-all;
								}
            }
        }
    }
    &--open{
        .accordion{
            &__btn {
                &:after {
                    display: none;
                }
            }
        }

    }
}
h2 + .accordion--outline{
    margin-top: 32px;
    @media(max-width: 799px) {
        margin-top: 24px;
    }
}
