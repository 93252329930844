.btn{
	font-size: rem(18px);
	line-height: rem(25px);
	font-weight: 500;
	border-width: 2px;
	padding: 12px 32px;
	border-radius: 35px;
	text-decoration: none;
	&:active, &:focus{
		background-color: transparent;
		border-color: transparent;
		box-shadow: none!important;
	}
	&-primary {
		color: #fff;
		background-color: $violet-100;
		border-color: $violet-100;
		&:hover, &:active, &:focus{
			background-color: $violet-80!important;
			border-color: $violet-80!important;
		}
	}
	&-secondary {
		color: $violet-100;
		background-color: $white;
		border-color: $violet-100;
		&:hover, &:active, &:focus{
			color: $violet-80!important;
			background-color: $white!important;
			border-color: $violet-80!important;
		}
	}
	&-outline-primary {
		color: $violet-100;
		background-color: $white;
		border-color: $violet-100;
		&:hover, &:active, &:focus{
			color: $violet-80!important;
			background-color: $white!important;
			border-color: $violet-80!important;
		}
	}
	&-outline-secondary {
		color: $blueDark;
		background-color: #fff;
		border-color: $blue;
		&:hover, &:active, &:focus{
			color: $blueDark!important;
			background-color: #fff!important;
			border-color: $blue!important;
		}
	}

	&-link {
		font-size: inherit;
		color: inherit;
		text-decoration: underline;
		padding: 0;
		display: inline;
		border-radius: 5px;
		&:hover {
			color: inherit;
		}
		&--arrow {
			&:before{
				content: '';
				margin-right: 9px;
				vertical-align: middle;
				display: inline-block;
				width: 32px;
				height: 32px;
				background: url(../images/arrow-right.svg);
				background-size: contain;
			}
		}
		&--back {
			&:before{
				content: '';
				margin-right: 8px;
				vertical-align: middle;
				display: inline-block;
				width: 16px;
				height: 16px;
				background: url(../images/link-back.svg) no-repeat;
				background-size: contain;
			}
		}
	}
}

.btn-box{
	margin-left: -32px;
	margin-bottom: -24px;
	&:empty{
		margin: 0;
	}
	.btn{
		display: inline-block;
		margin-left: 32px;
		margin-bottom: 24px;
	}
}

.btn-box{
	margin-left: -32px;
	margin-bottom: -24px;
	&:empty{
		margin: 0;
	}
	.btn{
		display: inline-block;
		margin-left: 32px;
		margin-bottom: 24px;
	}
}
