.box-gray {
	padding: 48px 52px;
	background-color: $grayLight;
	margin-bottom: 16px;
	h3 {
		margin-top: 40px;
		&:first-of-type{
			margin-top: 0;
		}
	}
	@media(max-width: 400px){
		padding: 32px 24px;
	}
	*:last-child {
		margin-bottom: 0;
	}

	a{
		color: $blueDark;
		font-size: inherit;
		text-decoration: underline;
		padding: 0;
		display: inline;
		border-radius: 5px;
		&:hover {
			color: inherit;
		}
		&:before{
			content: '';
			margin-right: 9px;
			vertical-align: middle;
			display: inline-block;
			width: 32px;
			height: 32px;
			background: url(../images/arrow-right.svg);
			background-size: contain;
		}
	}

}
.box-outline{
	padding: 40px 48px 48px;
	border: 2px solid $blueDark;
	border-radius: 11px;
	margin: 12px 42px 0;
	position: relative;
	@media(max-width: 1199px){
		margin: 16px 0 0;
	}
	@media(max-width: 799px) {
		padding: 40px 24px 32px;
	}
	&::before{
		content: '';
		background-color: #fff;
		border: 2px solid $blueDark;
		border-radius: 40px;
		margin-left: -60px;
		position: absolute;
		width: 120px;
		height: 24px;
		top: -12px;
		left: 50%;
		@media(max-width: 799px){
			height: 32px;
			width: 16.67%;
			top: -16px;
			margin-left: -8.335%;
		}
	}
}
.box-blue {
	padding: 40px 48px;
	background-color: $yellow-20;
	border: 2px solid $violet-100;
	color: $violet-100;
	border-radius: 8px;
	margin-left: 42px;
	@media(max-width: 1199px){
		margin-left: 0;
	}
	@media(max-width: 799px) {
		padding: 32px 24px;
	}
}

