.form__field{
	input[type=text], input[type=tel], input[type=email], .select, select, textarea{
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		display: block;
		border-radius: 0%;
		border: 1px solid $blueDark;
		margin-bottom: 16px;
		background-color: #FFFFFF;
		padding: 10px;
		color: $black2;
		width: 100%;
	};

	.bootstrap-select.select{
		width: 100%!important;
		padding: 0;

		.btn{
			color: $black2;
			padding: 10px;
			border: none;
			border-radius: 0%;
			background-color: #FFFFFF;

			&:active,
			&:hover,
			&:focus{
				color: inherit;
				background-color: inherit;
				border-color: inherit;
				outline: none!important;
			}

			&:after {
				display: inline-block;
				margin-left: 0.255em;
				vertical-align: 0.255em;
				content: "";
				border-top: 12px solid;
				border-right: 7.5px solid transparent;
				border-bottom: 0;
				border-left: 7.5px solid transparent;
				margin-right: 6px;
			}

		}

		&.dropup{
			.btn{
				&:after {
					display: inline-block;
					margin-left: 0.255em;
					vertical-align: 0.255em;
					content: "";
					border-top: 0;
					border-right: 7.5px solid transparent;
					border-bottom: 12px solid;
					border-left: 7.5px solid transparent;
				}
			}
		}

		&.show{

			.btn{
				&:after {
					display: inline-block;
					margin-left: 0.255em;
					vertical-align: 0.255em;
					content: "";
					border-top: 0;
					border-right: 7.5px solid transparent;
					border-bottom: 12px solid;
					border-left: 7.5px solid transparent;
				}
			}

			&.dropup{
				.btn{
					&:after {
						display: inline-block;
						margin-left: 0.255em;
						vertical-align: 0.255em;
						content: "";
						border-top: 12px solid;
						border-right: 7.5px solid transparent;
						border-bottom: 0;
						border-left: 7.5px solid transparent;
					}
				}
			}
		}
	}
	.dropdown-menu{
		border: 1px solid $blueDark;
		border-radius: 0;
		top: 0!important;
		margin-top: 0!important;
		max-height: 300px;

		.dropdown-menu li{
			margin-bottom: 0;
		}

		.dropdown-item{
			padding: 10px;
			border-radius: 0;
			&.active,
			&:active{
				background: #F5F5F5;
				color: inherit;
			}
		}

	}

	textarea{
		outline-style: none;
		min-height: 140px;
		resize: none;
		&:focus, &:active{
			border: 2px solid $blueDark;
		}
	}
	input{
		outline-style: none;
		&[type=text], &[type=tel], &[type=email]{
			height: 45px;
		}
		&:active, &:focus{
			border: 2px solid $blueDark;
		};
		&:invalid,
		&.invalid{
			border: 2px solid $red;
		}
		&:disabled,
		&.disabled{
			background-color: $grayLight;
			border: 2px solid $gray;
			color: $gray;
			pointer-events: none;
		}
	};
	label{
		&:active, &:focus{
			color: $blueDark;
		};
		margin-bottom: 7px;
		color: $black2;
		font-size: rem(18px);
		line-height: 1.2;
		font-weight: 600;
	};
}

input[type=submit]:only-of-type{
	margin-bottom: 48px;
}
