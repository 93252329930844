.article {
    &__header {
        color: $violet-100;
        padding-top: 90px;
        @media (max-width: 1279px) {
            padding-top: 64px;
        }
        @media (max-width: 799px) {
            padding-top: 24px;
				}
        h1{
						margin-top: 40px;
						margin-bottom: 24px;
            @media (max-width:	799px) {
                margin-top: 24px;
            }
				}
				a + h1{
					margin-top: 24px;
					margin-bottom: 0;
				}
        p {
            font-size: rem(21px);

        }
        .image {
            width: 100%;
            max-width: 100%;

            height: auto;
        }
        .row > :first-child {
            @media (max-width:	799px) {
                order: 2;
            }
        }
    }
    &__content {
			color: $violet-100;

        h2, h3, h4, p, img, .box-gray {
            margin-bottom: 16px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        p {
					font-size: rem(18px);
					line-height: rem(25px);
        }
        ul, ol {
            padding-left: 50px;
            margin-bottom: 16px;
            &+ h2 {
                margin-top: 48px;
            }
        }
        p + h2 {
            margin-top: 48px;
            @media(max-width: 400px){
                margin-top: 24px;
            }
		}
        h2 + p {
            margin-bottom: 8px !important;
        }
        img {
            max-width: 100%;
        }
        .editor-align-left {
            margin-right: 16px;
        }
        .editor-align-right {
            margin-left: 16px;
        }
        .box-gray{
            @media (max-width:	799px) and (min-width:	401px) {
                margin: 0 -52px 16px;
            }
				}
				input[type=text], input[type=tel], input[type=email], .select, select, textarea{
					max-width: 255px;
				}
				textarea{
					max-width: 374px;
				}
		}
}
