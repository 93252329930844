// Grid config
@import "src/assets/sass/base/grid-config";

//import bootstrap
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/button-group";
// @import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/close";
// @import "node_modules/bootstrap/scss/code";
// @import "node_modules/bootstrap/scss/custom-forms";
// @import "node_modules/bootstrap/scss/forms";
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/input-group";
// @import "node_modules/bootstrap/scss/jumbotron";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/nav";
// @import "node_modules/bootstrap/scss/navbar";
// @import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/print";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/root";
// @import "node_modules/bootstrap/scss/spinners";
// @import "node_modules/bootstrap/scss/tables";
// @import "node_modules/bootstrap/scss/toasts";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/transitions";
// @import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/utilities";

//base application
@import "src/assets/libs/bootstrap-select/dist/css/bootstrap-select.min";
@import "src/assets/sass/base/mixins";
@import "src/assets/sass/base/color";
@import "src/assets/sass/base/typography";
@import "src/assets/sass/base/global";
@import "src/assets/sass/base/image-aspect-ratio";
@import "src/assets/sass/base/cards";
@import "src/assets/sass/base/buttons";
@import "src/assets/sass/base/forms";

//components
@import "src/assets/sass/components/header";
@import "src/assets/sass/components/footer";
@import "src/assets/sass/components/moduloes-front-page";
@import "src/assets/sass/components/article";
@import "src/assets/sass/components/accordion";
@import "src/assets/sass/components/alert";
@import "src/assets/sass/components/search-part";

/** CSS rules used to override the last section padding rules from original design - Begin */
body .section:last-of-type>div:first-of-type {
	padding-bottom: unset
}

body .section:last-of-type {
	padding-bottom: unset;
	margin-bottom: unset
}

body .section.section-foundation>div:first-child {
	padding-bottom: 128px;
	margin-bottom: 0
}

body .section.section-foundation {
	padding-bottom: 0;
	margin-bottom: 0
}

body .section.section-foundation>div:first-child>div:first-child {
	padding-bottom: 0;
	margin-bottom: 0
}

@media (max-width: 799px) {
	body .section:last-of-type {
		padding-bottom: unset
	}

	body .section.section-foundation>div:first-child {
		padding-bottom: 40px
	}

	body .section.section-foundation>div:first-child>div:first-child {
		padding-bottom: 0
	}
}

/** CSS rules used to override the last section padding rules from original design - End */

/** Overriding anchor style inside box-gray due to use inside HtmlArea - Begin
.article__content .box-gray a {
	color: #23205D;
	font-size: 16px
}

.article__content .box-gray a::before {
	content: '';
	margin-right: 9px;
	vertical-align: middle;
	display: inline-block;
	width: 32px;
	height: 32px;
	background-size: contain;
	background-image: url('../images/arrow-right.svg')
}

Overriding anchor style inside box-gray due to use inside HtmlArea - End */

/** Responsive video inside iframe - Begin */
.videoWrapper {
	position: relative;
	/* 16꞉9 */
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

/** Responsive video inside iframe - End */

/** Form fields should not mark as invalid prior to submitting - Begin */

.form__field .xp-input:invalid,
.form__field .xp-input.invalid {
	border: 2px solid #CC0000;
}

.form__field .xp-input.pristine:invalid:not(:active):not(:focus):not(:disabled):not(.disabled),
.form__field .xp-input.pristine.invalid:not(:active):not(:focus):not(:disabled):not(.disabled) {
	border: 1px solid #23205D;
	box-shadow: none;
}

.form__field .xp-input.pristine:invalid:active,
.form__field .xp-input.pristine:invalid:focus,
.form__field .xp-input.pristine.invalid:active,
.form__field .xp-input.pristine.invalid:focus {
	border: 2px solid #23205D;
	box-shadow: none;
}

/** Form fields should not mark as invalid prior to submitting - End */

/** Search box of the default page - Begin */

.search__field {
	z-index: 1;
}

/** Search box of the default page - End */
