.moduleFrontPage {
	.row {
		align-items: center;
	}
	&-leftImage {
		// padding: 64px 0;
		padding-bottom: 64px;
		@media (max-width: 799px) {
			// padding: 40px 0;
			padding-bottom: 40px;
		}
		@media (max-width: 400px) {
			// padding: 24px 0;
			padding-bottom: 24px;
		}
		.image {
			width: 100%;
		}
		.content {
			padding-left: 7.3%;
			@media (max-width: 799px) {
				padding-left: 0;
				margin-top: 24px;
			}
			h1 {
				margin-bottom: 8px;
				color: $violet-100;
				@media (max-width: 1079px) {
					margin-bottom: 0px;
				}
				@media (max-width: 799px) {
					margin-bottom: 8px;
				}
			}
			p {
				margin-bottom: 24px;
				font-size: 21px;
				line-height: normal;
				color: $violet-100;
				@media (max-width: 799px) {
					margin-bottom: 16px;
					font-size: 18px;
					line-height: 25px;
				}
			}
			.btnWrapper {
				margin-bottom: 16px;
				@media (max-width: 400px) {
					text-align: center;
				}
			}
			& *:last-child {
				margin-bottom: 0;
			}
		}
	}
	&-rightImage {
		padding: 16px 0 0 0;
		@media (max-width: 400px) {
			padding: 0;
		}
		.image {
			width: 100%;
			margin-top: -16px;
			@media (max-width: 799px) {
				margin-top: 0;
			}
		}
		.content {
			padding-right: 9%;
			@media (max-width: 799px) {
				padding-right: 0;
				margin-top: 24px;
			}
			h1 {
				color: $violet-100;
				margin-bottom: 8px;
				@media (max-width: 1079px) {
					margin-bottom: 0px;
				}
				@media (max-width: 799px) {
					margin-bottom: 8px;
				}
			}
			p {
				font-size: 21px;
				line-height: normal;
				margin-bottom: 24px;
				color: $violet-100;
				@media (max-width: 799px) {
					margin-bottom: 16px;
					font-size: 18px;
					line-height: 25px;
				}
			}
			.btnWrapper {
				margin-bottom: 16px;
				@media (max-width: 400px) {
					text-align: center;
				}
			}
			& *:last-child {
				margin-bottom: 0;
			}
		}
		.row {
			@media (max-width: 799px) {
				flex-direction: column-reverse;
			}
		}
	}

	.content {
		.btnWrapper {
			a {
				background-color: $violet-100;
				border-color: $violet-100;
			}
		}
	}
}
