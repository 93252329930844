.searchPart {
  margin-top: -50px;
  margin-bottom: 190px;
  @media (max-width: 400px) {
    margin-top: -35px;
    margin-bottom: 50px;
  }

  // &__title {
  //   h1 {
  //     margin-bottom: 16px;
  //   }
  // }

  &__field {
    margin-bottom: 50px;
    @media (max-width: 400px) {
      margin-bottom: 24px;
    }
    &--wrapper {
      border-radius: 0px 35px 35px 0px;
      overflow: hidden;
      border: 1px solid $violet-100;
      position: relative;
      margin-bottom: 32px;

      @media (max-width: 400px) {
        margin-bottom: 16px;
      }

      ::-webkit-input-placeholder {
        color: $violet-100;
      }

      :-ms-input-placeholder {
        color: $violet-100;
      }

      ::placeholder {
        color: $violet-100;
      }

      input {
        padding: 10px 20px 10px 20px;
        font-weight: 500;
        font-size: rem(18px);
        line-height: rem(18px);
        color: $violet-100;
        border: none;
        width: 100%;
        padding-right: 70px;
        &:active,
        &:focus {
          outline: none;
        }
      }

      button {
        border: none;
        height: 100%;
        padding: 10px 44px 12px 12px;
        background-color: $violet-100;
        color: #ffffff;
        font-weight: 500;
        font-size: rem(18px);
        line-height: rem(18px);
        position: absolute;
        top: 0;
        right: 0;
        &:after {
          content: "";
          width: 18px;
          height: 18px;
          position: absolute;
          margin-top: -9px;
          top: 50%;
          right: 15px;
          background: url(../images/search-light.svg) no-repeat center center;
          background-size: 100%;
        }
      }
    }
    &--info {
      color: $violet-100;
      font-weight: 500;
      font-size: rem(18px);
      line-height: rem(25px);
      span {
        font-weight: 600;
      }
    }
  }

  &__results {
    &--item {
      padding-bottom: 24px;
      border-bottom: 1px solid $violet-80;
			border-radius: 0;
      margin-bottom: 24px;
      text-decoration: none;
      display: block;
      &:hover {
        text-decoration: none;
      }
      &:last-child {
        margin-bottom: 64px;
        @media (max-width: 400px) {
          margin-bottom: 50px;
        }
      }
      h2 {
        color: $violet-100;
        font-weight: 600;
        font-size: rem(24px);
        line-height: rem(32px);
        margin-bottom: 7px;
      }
      p {
        color: $violet-100;
        font-weight: 400;
        font-size: rem(18px);
        line-height: rem(24px);
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  &__showMore {
    text-align: center;
  }
}
