footer{
    font-size: rem(18px);
    padding: 32px 12px 32px 45px;
    background-color: $violet-100;
    color: $beige-10;
    @media (max-width: 800px) {
        padding: 24px 0 0;
    }
    .col-sm-12 {
        @media (max-width: 800px) {
           margin-bottom: 16px;
        }
    }
    h4 {
        font-size: rem(20px);
        font-weight: 600;
        margin-bottom: 8px;
    }
    p {
        margin-bottom: 8px;
    }
    .btn-link, a {
        font-weight: 600;
        &:focus{
            box-shadow: 0 0 0 2px rgba($blueLight, .4);
        }
    }
}
